import { ContentBlocks } from "eddev/blocks"
import { defineView } from "eddev/views"

export default defineView("front-page", (props) => {
  return (
    <div>
      <ContentBlocks
        blocks={props.page?.contentBlocks}
        wrapBlock={(children, ctx) => {
          if (ctx.prev?.props?.gridlines && !ctx.current.props?.gridlines) {
            return (
              <>
                <div className={`subtheme-${ctx.prev.props.scheme} block w-full h-[1px] bg-ruler`} />
                {children}
              </>
            )
          }
          return <>{children}</>
        }}
      />
    </div>
  )
})
